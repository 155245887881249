import ImgParallaxComponent from '../../Utils/ImgParallaxComponent';

const WorkDetailsHeader = ({ title, backgroundUrl }) => {
  return (
    <div className="work-header">
      <h1 className="work-header--title title-1 scroll-text">{title}</h1>
      <ImgParallaxComponent imgUrl={backgroundUrl} />
    </div>
  );
};

export default WorkDetailsHeader;
