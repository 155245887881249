import React, { useState, useRef } from 'react';
import { Plane, useCurtains } from 'react-curtains';
import { Vec2 } from 'curtainsjs';

import { errorVS, errorFS } from './errorShaders';

import './styles.scss';

const ErrorPlane = () => {
  const [plane, setPlane] = useState(null);

  const mousePosition = useRef(new Vec2());
  const mouseLastPosition = useRef(new Vec2());

  const deltas = useRef({
    max: 0,
    applied: 0,
  });

  const planeRef = useRef();
  const imgRef = useRef();

  const basicUniforms = {
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    resolution: {
      name: 'uResolution',
      type: '2f',
      value: [0, 0],
    },
    mousePosition: {
      name: 'uMousePosition',
      type: '2f',
      value: mousePosition.current,
    },
    mouseMoveStrength: {
      name: 'uMouseMoveStrength',
      type: '1f',
      value: 0,
    },
  };

  useCurtains(
    (curtains) => {
      // console.log('curtains:', curtains);
      curtains.planes.forEach((planeContainer) => {
        // console.log(planeContainer);
        const onMouseMove = (event) => {
          // Set mouse position values for last position
          mouseLastPosition.current.copy(mousePosition.current);

          const mouse = new Vec2();

          // Handles when mouse position when touching object
          if (event.targetTouches) {
            // console.log(event.targetTouches);
            mouse.set(event.targetTouches[0].clientX, event.targetTouches[0].clientY);
          } else {
            mouse.set(event.clientX, event.clientY);
          }

          // Lerps for smooooooth
          mousePosition.current.set(
            curtains.lerp(mousePosition.current.x, mouse.x, 0.3),
            curtains.lerp(mousePosition.current.y, mouse.y, 0.3)
          );

          // Calculates strength of mouvement
          if (mouseLastPosition.current.x && mouseLastPosition.current.y) {
            let delta =
              Math.sqrt(
                Math.pow(mousePosition.current.x - mouseLastPosition.current.x, 2) +
                  Math.pow(mousePosition.current.y - mouseLastPosition.current.y, 2)
              ) / 30;

            delta = Math.min(4, delta);

            // Handles delta update if increased
            if (delta >= deltas.current.max) {
              deltas.current.max = delta;
            }
          }

          // If plane, update mouse position uniforms
          if (planeContainer) {
            planeContainer.uniforms.mousePosition.value = planeContainer.mouseToPlaneCoords(mousePosition.current);
          }
        };

        // Sets listeners on planes
        if (planeRef?.current) {
          // Handle mouseover
          planeRef.current.addEventListener('mousemove', onMouseMove);
          // Handle touch
          planeRef.current.addEventListener('touchmove', onMouseMove, { passive: true });
        }
        // Cleans page on unmount
        return () => {
          planeRef.current.removeEventListener('mousemove', onMouseMove);
          planeRef.current.removeEventListener('touchmove', onMouseMove, { passive: true });
        };
      });
    },
    [plane]
  );

  // Handles resolution
  const setResolution = (plane) => {
    const planeBBox = plane.getBoundingRect();
    plane.uniforms.resolution.value = [planeBBox.width, planeBBox.height];
  };

  // When plane ready
  const onReady = (plane) => {
    plane.setPerspective(35);

    deltas.current.max = 2;

    setResolution(plane);
    setPlane(plane);
  };

  const onRender = (plane) => {
    // Time uniforms
    plane.uniforms.time.value += 1;

    // Applied deformation to deltas
    deltas.current.applied += (deltas.current.max - deltas.current.applied) * 0.02;
    deltas.current.max += (0 - deltas.current.max) * 0.01;

    // Update mouse strength value
    plane.uniforms.mouseMoveStrength.value = deltas.current.applied;

    plane.updatePosition();
  };

  // Resolution after resize
  const onAfterResize = (plane) => {
    setResolution(plane);
  };

  return (
    <div ref={planeRef} className="errorPlane">
      <div className="errorPlane-container">
        <Plane
          className="errorPlane-container_plane"
          // plane init parameters
          vertexShader={errorVS}
          fragmentShader={errorFS}
          uniforms={basicUniforms}
          widthSegments={20}
          heightSegments={20}
          // plane events
          onReady={onReady}
          onAfterResize={onAfterResize}
          onRender={onRender}
        >
          <img ref={imgRef} src="/img/works/divers/divers-13.jpg" data-sampler="simplePlaneTexture" alt="" />
        </Plane>
      </div>
    </div>
  );
};

export default ErrorPlane;
