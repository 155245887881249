import React from 'react';

import './styles.scss';

const LoadingComponent = () => {
  return (
    <div className="loading">
      <div className="loading-spinner" />
    </div>
  );
};

export default LoadingComponent;
