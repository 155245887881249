import { useEffect } from 'react';
import Contacts from '../Contacts';
import gsap from 'gsap';

import clients from '../../data/clients.json';

import { horizontalLoop } from '../../utils/horizontalLoop';

import './styles.scss';

const About = () => {
  useEffect(() => {
    const items = gsap.utils.toArray('.item');

    gsap.fromTo(
      items,
      {
        y: 150,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        ease: 'expo',
        stagger: {
          each: 0.1,
        },
      }
    );
  }, []);

  useEffect(() => {
    const clientsList = gsap.utils.toArray('.client');
    horizontalLoop(clientsList, {
      paused: false,
      repeat: -1,
      speed: 2,
      // gap is 0.5rem so by default 8px
      paddingRight: 8,
    });
  }, []);

  return (
    <div className="about">
      <h2 className="about-title item">Hello I'm Antoine&thinsp;!</h2>
      <div className="about-text item">
        Développeur web fullstack passionné de nouvelles technologies, je créée des outils de communication web
        personnalisés, tentant de proposer des expériences de navigations uniques et modernes, répondant aux contraintes
        d'accessibilités actuelles.
      </div>
      <div className="about-clients item">
        Selected clients :
        <div className="about-clients--list">
          <div className="about-clients--list_elements">
            {clients.map((client, i) => (
              <span key={i} className="about-clients--list_elements-el client">
                {client} -
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="about-text item">
        Directeur artistique freelance depuis 2014, je vis et travaille depuis Paris. Je couple ma formation de designer
        graphique avec mes expériences au sein de studios de graphisme à taille humaine et d'agences de design
        internationales à Paris ou New York afin de vous conseiller et de vous accompagner dans vos démarches de
        communication.
      </div>
      <Contacts />
    </div>
  );
};

export default About;
