import { useEffect } from 'react';

import gsap from 'gsap';

import './styles.scss';

const ImgParallaxComponent = ({ imgUrl = '../../../../public/img/works/formacoach/formacoach-mockup.png' }) => {
  useEffect(() => {
    const images = gsap.utils.toArray('.gsap-parallax-image');

    images.forEach((image) => {
      gsap.fromTo(
        image,
        {
          y: (image.parentElement.offsetHeight - image.offsetHeight) * 2,
        },
        {
          y: 0,
          ease: 'none',
          scrollTrigger: {
            trigger: image.parentElement,
            scrub: true,
            // markers: true,
          },
        }
      );
    });
  }, []);

  return (
    <div className="gsap-parallax">
      <div className="gsap-parallax-container">
        <div
          className="gsap-parallax-image"
          style={{
            backgroundImage: imgUrl,
          }}
        />
      </div>
    </div>
  );
};

export default ImgParallaxComponent;
