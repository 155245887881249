import React from 'react';

import './styles.scss';

const VideoComponent = ({ videoUrl, posterUrl }) => {
  return (
    <>
      {videoUrl && posterUrl && (
        <video className="video" src={`${videoUrl}`} width={900} autoPlay loop muted poster={`${posterUrl}`}></video>
      )}
    </>
  );
};

export default VideoComponent;
