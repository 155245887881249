import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { Curtains } from 'react-curtains';

import './styles/index.scss';
import App from './components/App/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Curtains pixelRatio={Math.min(1.5, window.devicePixelRatio)}>
    <Router>
      <App />
    </Router>
  </Curtains>
);
