import { useEffect, useState, createRef, forwardRef } from 'react';
import { useParams } from 'react-router-dom';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import works from '../../data/works.json';

// Components
import Contacts from '../Contacts';
import LoadingComponent from '../Utils/LoadingComponent';

import './styles.scss';
import WorkDetailsHeader from './WorkDetailsHeader';

const ImageComponent = forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const { url, slug, id } = props;

  useEffect(() => {
    if (id !== '7') {
      gsap.from(ref?.current, {
        y: 100,
        opacity: 0,
        duration: 0.5,
        scrollTrigger: {
          trigger: '.work-imgs',
          // scrub: 1,
          start: 'top bottom',
          end: 'top bottom',
        },
        stagger: {
          each: 0.05,
        },
      });
    }
  }, [ref, id]);

  const handleImgLoaded = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <LoadingComponent />}
      <img
        key={slug}
        ref={ref}
        className="work-imgs--img"
        src={url}
        alt={slug}
        onLoad={handleImgLoaded}
        loading="lazy"
      />
    </>
  );
});

const WorkDetails = () => {
  const [workDetails, setWorkDetails] = useState(null);
  const [imgList, setImgList] = useState([]);
  const { id } = useParams();

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const details = works.find((work) => work.id.toString() === id);
    setWorkDetails(details);
  }, [id]);

  useEffect(() => {
    workDetails?.images?.forEach((image) => {
      setImgList((prev) => [...prev, `/img/works/${workDetails?.slug}/${image}.jpg`]);
    });
  }, [workDetails]);

  useEffect(() => {
    const textElsArray = gsap.utils.toArray('.scroll-text');

    textElsArray.forEach((el) => {
      gsap.from(el, {
        y: 50,
        opacity: 0,
        scrollTrigger: {
          trigger: el,
          scrub: 2,
          start: 'top bottom',
          end: '60% 80%',
        },
      });
    });
  }, [workDetails]);

  return (
    <>
      {workDetails && (
        <div className="work">
          <WorkDetailsHeader title={workDetails?.title} backgroundUrl={`url(${imgList[0]})`} />
          <p className="work-summary scroll-text">{workDetails?.summary}</p>
          {workDetails?.description ? (
            <>
              <div className="work-imgs">
                {imgList?.slice(1, 3).map((img, index) => {
                  const imgRef = createRef();
                  return <ImageComponent key={index} slug={workDetails?.slug} url={img} ref={imgRef} id={id} />;
                })}
              </div>
              <p className="work-description scroll-text">{workDetails?.description}</p>
              <div
                className="work-categories scroll-text"
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <div className="work-categories--cats">
                  <ul className="work-categories--cats_list scroll-text">
                    {workDetails?.categories?.map((category) => (
                      <li key={category}>{category}</li>
                    ))}
                  </ul>
                </div>
                {workDetails?.dev_languages && (
                  <div className="work-categories--lang">
                    <ul className="work-categories--lang_lang scroll-text">
                      <p>Technologies utilisées :</p>
                      {workDetails?.dev_languages?.map((lang) => (
                        <li key={lang}>{lang}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div
                className="work-imgs"
                style={{
                  gridTemplateColumns: `${id === '7' ? 'none' : 'repeat(2, 1fr)'}`,
                  rowGap: `${id === '7' ? '0' : '1rem'}`,
                }}
              >
                {imgList?.slice(3).map((img, index) => {
                  const imgRef = createRef();
                  return <ImageComponent key={index} slug={workDetails?.slug} url={img} ref={imgRef} id={id} />;
                })}
              </div>
            </>
          ) : (
            <div
              className="work-imgs"
              style={{
                gridTemplateColumns: `${id === '7' ? 'none' : 'repeat(2, 1fr)'}`,
                rowGap: `${id === '7' ? '0' : '1rem'}`,
              }}
            >
              {imgList?.slice(1).map((img, index) => {
                const imgRef = createRef();
                return <ImageComponent key={index} slug={workDetails?.slug} url={img} ref={imgRef} id={id} />;
              })}
            </div>
          )}
        </div>
      )}
      <div style={{ padding: '0 1rem' }}>
        <Contacts />
      </div>
    </>
  );
};

export default WorkDetails;
