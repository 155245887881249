import { createContext } from 'react';

import works from '../data/works.json';

export const ReducerContext = createContext(null);
export const ReducerDispatchContext = createContext(null);

export const initialState = {
  works: [...works],
  workIsOpen: false,
  isLoading: true,
};

const appReducer = (state = initialState, action) => {
  // console.log('action', action);
  switch (action.type) {
    // LOADING REDUCER
    case 'start_loading': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'stop_loading': {
      return {
        ...state,
        isLoading: false,
      };
    }
    // WORKS REDUCER
    case 'open_work': {
      return {
        ...state,
        id: action.id,
        workIsOpen: true,
      };
    }
    case 'close_work': {
      return {
        ...state,
        id: action.id,
        workIsOpen: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
