import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';

import './styles.scss';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

const LogoComponent = ({ setInitialLoading }) => {
  if (typeof window !== 'undefined') {
    gsap.registerPlugin(DrawSVGPlugin);
  }

  const logoRef = useRef();

  const logoDrawnTl = useRef();
  logoDrawnTl.current = gsap.timeline({
    paused: true,
    defaults: {
      duration: 0.15,
      ease: 'power3.in',
    },
  });

  const animateAntoine = () => {
    const helloElements = gsap.utils.toArray('.logo-hello');

    const drawFrom = {
      drawSVG: '0% 0%',
    };

    const drawTo = {
      drawSVG: true,
    };

    logoDrawnTl.current
      .fromTo(
        helloElements,
        { y: 50, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
          delay: 1,
          ease: 'expo',
          stagger: { each: 0.1 },
        }
      )
      .fromTo('#antoine-mask-path-A-1', { ...drawFrom }, { ...drawTo, duration: 0.3 })
      .fromTo('#antoine-mask-path-A-2', { ...drawFrom }, { ...drawTo })
      .fromTo('#antoine-mask-path-N-1', { ...drawFrom }, { ...drawTo })
      .fromTo('#antoine-mask-path-N-2', { ...drawFrom }, { ...drawTo })
      .fromTo('#antoine-mask-path-N-3', { ...drawFrom }, { ...drawTo })
      .fromTo('#antoine-mask-path-T-1', { ...drawFrom }, { ...drawTo })
      .fromTo('#antoine-mask-path-O', { ...drawFrom }, { ...drawTo })
      .fromTo('#antoine-mask-path-I-1', { ...drawFrom }, { ...drawTo })
      .fromTo('#antoine-mask-path-I-2', { ...drawFrom }, { ...drawTo })
      .fromTo('#antoine-mask-path-N2-1', { ...drawFrom }, { ...drawTo })
      .fromTo('#antoine-mask-path-N2-2', { ...drawFrom }, { ...drawTo })
      .fromTo('#antoine-mask-path-N2-3', { ...drawFrom }, { ...drawTo })
      .fromTo('#antoine-mask-path-E', { ...drawFrom }, { ...drawTo, duration: 0.2 })
      .fromTo('#antoine-mask-path-I-3', { ...drawFrom }, { ...drawTo, duration: 0.25 })
      .fromTo('#antoine-mask-path-T-2', { ...drawFrom }, { ...drawTo, duration: 0.25 });
    // .to('.initial-loading', {
    //   display: 'none',
    //   onComplete: () => {
    //     console.log('animation complete');
    //     setInitialLoading(true);
    //   },
    // });

    logoDrawnTl.current.play();
  };

  useEffect(() => {
    animateAntoine();
    return () => {
      // logoDrawnTl.current.kill();
    };
  }, []);

  return (
    <div className="antoine-logo-container">
      <svg
        ref={logoRef}
        className="mainlogo"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox={`0 200 1920 ${window.innerHeight}`}
        width={window.innerWidth}
        xmlSpace="preserve"
      >
        <defs>
          <g>
            <mask id="antoine-mask-letter-A-1">
              <path
                id="antoine-mask-path-A-1"
                fill="none"
                stroke="#ffffff"
                strokeWidth="26"
                d="M816.765,553.843  c-77.852,4.864-236.62,48.356-236.62,113.384c0,58.693,174.761-39.479,280.748-243.007"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-A-2">
              <path
                id="antoine-mask-path-A-2"
                fill="none"
                stroke="#ffffff"
                strokeWidth="26"
                d="M887.256,395.823c-27.868,49.826-90.28,165.038-86.694,253.777"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-N-1">
              <path
                id="antoine-mask-path-N-1"
                fill="none"
                stroke="#ffffff"
                strokeWidth="26"
                d="M877.835,485.789c-9.501,21.722-35.153,75.24-36.42,107.227"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-N-2">
              <path
                id="antoine-mask-path-N-2"
                fill="none"
                stroke="#ffffff"
                strokeWidth="13"
                d="M849.016,593.016c2.961-21.95,44.274-87.628,74.55-105.252"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-N-3">
              <path
                id="antoine-mask-path-N-3"
                fill="none"
                stroke="#ffffff"
                strokeWidth="26"
                d="M927.555,489.142c-12.033,16.786-26.918,57.637-28.817,96.591"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-T-1">
              <path
                id="antoine-mask-path-T-1"
                fill="none"
                stroke="#ffffff"
                strokeWidth="26"
                d="M991.422,409.231c-27.025,69.67-70.938,163.413-69.25,226.327"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-T-2">
              <path
                id="antoine-mask-path-T-2"
                fill="none"
                stroke="#ffffff"
                strokeWidth="15"
                d="M880.528,481.329c54.154-16.572,115.912-21.808,144.728-18.791"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-O">
              <path
                id="antoine-mask-path-O"
                fill="none"
                stroke="#ffffff"
                strokeWidth="26"
                d="M1019.08,501.915c0-6.548-1.057-14.151-7.6-14.151c-10.453,0-36.314,17.74-36.314,49.673c0,6.921,4.223,16.699,14.355,16.699c7.6,0,29.527-17.705,29.527-54.012"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-I-1">
              <path
                id="antoine-mask-path-I-1"
                fill="none"
                stroke="#ffffff"
                strokeWidth="26"
                d="M1048.427,483.124c-3.801,10.979-18.793,51.517-17.313,66.716"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-I-2">
              <path
                id="antoine-mask-path-I-2"
                fill="none"
                stroke="#ffffff"
                strokeWidth="13"
                d="M1036.281,549.84c5.563-5.919,30.195-23.856,43.867-78.117"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-I-3">
              <path
                id="antoine-mask-path-I-3"
                fill="none"
                stroke="#ffffff"
                strokeWidth="26"
                d="M1072.625,392.707c4.203,20.902-26.197,74.477-24.223,94.585"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-N2-1">
              <path
                id="antoine-mask-path-N2-1"
                fill="none"
                stroke="#ffffff"
                strokeWidth="26"
                d="M1081.844,481.329c-4.594,13.406-13.883,52.042-12.191,64.711"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-N2-2">
              <path
                id="antoine-mask-path-N2-2"
                fill="none"
                stroke="#ffffff"
                strokeWidth="13"
                d="M1075.644,546.04c19.471-54.51,43.199-96.914,58.295-114.224"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-N2-3">
              <path
                id="antoine-mask-path-N2-3"
                fill="none"
                stroke="#ffffff"
                strokeWidth="26"
                d="M1139.742,433.141c-17.945,33.727-32.512,96.01-33.568,126.199"
              />
            </mask>
          </g>
          <g>
            <mask id="antoine-mask-letter-E">
              <path
                id="antoine-mask-path-E"
                fill="none"
                stroke="#ffffff"
                strokeWidth="26"
                d="M1153.201,502.97c17.313-0.422,50.461-49.297,29.768-49.297c-20.689,0-67.98,79.065-26.813,79.065c41.172,0,73.895-61.438,73.262-80.648"
              />
            </mask>
          </g>
        </defs>
        <g className="mainlogo-elements">
          {/* LOGO */}
          <path
            id="letter-A-1"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-A-1)"
            fill="#2DD4C6"
            d="M817.81,548.946c-136.144,0-239.061,64.27-239.061,113.02c0,9.063,6.667,25.521,28.125,25.521
		c90.624,0,236.248-201.771,248.902-244.271c0-4.063,0.618-9.377-2.976-9.377c-52.178,108.126-185.926,235.312-248.635,235.312
		c-9.401,0-15.416-1.873-15.416-7.187c0-28.494,109.791-93.021,223.957-102.396C815.207,559.57,817.81,552.799,817.81,548.946z"
          />
          <path
            id="letter-A-2"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-A-2)"
            fill="#2DD4C6"
            d="M884.685,422.905c0,15.001-78.854,127.396-72.604,225.833c-9.583,0-21.667-19.793-21.667-23.335
		c0.001-52.707,72.917-227.081,83.333-227.081C876.873,398.322,884.685,416.651,884.685,422.905z"
          />
          <path
            id="letter-N1-1"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-N-1)"
            fill="#2DD4C6"
            d="M835.094,567.022c0,3.475,5.999,23.12,17.249,23.12c2.031,0,2.774-0.315,3.594-1.014
		c-1.483-0.627-2.192-2.477-2.192-4.809c0-22.771,28.858-75.739,28.858-82.248c0-6.512-9.469-19.376-18.02-19.376
		C864.582,501.967,835.094,550.456,835.094,567.022z"
          />
          <path
            id="letter-N1-2"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-N-2)"
            fill="#2DD4C6"
            d="M853.743,584.321c0-10.271,49.066-81.521,69.066-88.707c0-4.22-4.218-8.021-6.094-8.021
		c-13.75,0-66.875,81.613-66.875,88.02C849.842,579.518,851.237,584.321,853.743,584.321z"
          />
          <path
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-N-3)"
            id="letter-N1-3"
            fill="#2DD4C6"
            d="M916.664,487.592c4.454,0,12.812,14.582,12.812,19.583c0,5-25.103,64.269-21.874,80.313
		c-6.77,0-18.645-15.522-18.645-22.084C888.957,543.112,910.101,487.592,916.664,487.592z"
          />
          <path
            id="letter-T-1"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-T-1)"
            fill="#2DD4C6"
            d="M977.601,411.551c3.438,0,15.416,18.02,15.416,22.813c0,3.334-62.082,134.997-62.082,195.103
		c0,1.979,0.054,5.365,1.511,6.251c-2.031,2.031-20.184-17.839-20.184-22.137C912.262,561.862,977.601,430.091,977.601,411.551z"
          />
          <path
            id="letter-T-2"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-T-2)"
            fill="#2DD4C6"
            d="M1007.704,455.405c3.751,0,11.745,10.365,11.745,15.054c-7.227-1.643-15.702-2.894-34.217-2.894
		c-22.84,0-78.465,7.005-102.007,16.59c-2.817,1.127-1.354-4.582-1.354-4.582C912.81,467.489,967.497,455.405,1007.704,455.405z"
          />
          <path
            id="letter-O"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-O)"
            fill="#2DD4C6"
            d="M1006.564,481.029c-15.59,0-44.065,34.793-44.065,61.563c0,7.186,3.123,18.331,17.706,18.331
		c15.314,0,39.245-25.416,39.245-58.645C1019.45,493.01,1016.56,481.029,1006.564,481.029z M989.059,545.769
		c-2.396,0-3.905-2.657-3.905-6.015c0-16.642,18.828-43.359,25.351-43.359c2.447,0,2.93,4.296,2.93,6.404
		C1013.433,523.973,996.872,545.769,989.059,545.769z"
          />
          <path
            id="letter-I-1"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-I-1)"
            fill="#2DD4C6"
            d="M1035.967,483.275c7.669-0.271,15.206,13.889,15.069,18.025
		c-0.222,6.768-16.14,41.136-11.23,46.38c-6.181,4.957-20.127-6.053-20.04-8.707
		C1020.028,531.008,1038.499,490.236,1035.967,483.275z"
          />
          <path
            id="letter-I-2"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-I-2)"
            fill="#2DD4C6"
            d="M1036.329,548.93c10.505,0,37.313-50.818,41.167-61.236c-1.147-3.019-2.268-6.222-4.219-6.222
		c-4.845,12.786-25.313,60.155-39.451,60.155C1032.626,544.05,1033.954,547.071,1036.329,548.93z"
          />
          <path
            id="letter-I-3"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-I-3)"
            fill="#2DD4C6"
            d="M1039.762,468.19c0,3.479,9.218,18.908,18.516,18.908c0-22.772,22.03-62.683,22.03-69.193
		c0-6.512-11.563-22.082-16.354-22.082C1061.14,418.217,1039.762,451.627,1039.762,468.19z"
          />
          <path
            id="letter-N2-1"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-N2-1)"
            fill="#2DD4C6"
            d="M1072.862,481.003c3.125,0,11.704,11.483,11.704,17.461c0,2.109-14.049,35.189-5.038,44.931
		c-6.304,4.166-21.667-4.604-21.667-8.459C1057.861,524.78,1071.625,481.003,1072.862,481.003z"
          />
          <path
            id="letter-N2-2"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-N2-2)"
            fill="#2DD4C6"
            d="M1079.528,543.391c-2.104-3.74,40.313-92.335,52.187-101.777c0-2.875-3.568-7.096-6.146-7.096
		c-2.578,0-52.446,94.011-52.446,105.47C1073.122,541.446,1074.021,543.947,1079.528,543.391z"
          />
          <path
            id="letter-N2-3"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-N2-3)"
            fill="#2DD4C6"
            d="M1125.517,434.051c5.521,0,15.207,15.625,15.207,21.04c0,4.428-31.771,82.082-25.52,103.124
		c-7.135,0-17.189-16.509-17.189-19.791C1098.015,513.841,1122.183,434.051,1125.517,434.051z"
          />
          <path
            id="letter-E"
            className="antoine-logo-element"
            mask="url(#antoine-mask-letter-E)"
            fill="#2DD4C6"
            d="M1144.787,503.321c-1.875,0-8.333,6.875-2.604,6.875c17.864,0,49.923-34.974,49.923-47.707
		c0-3.438-7.425-17.267-11.563-17.267c-18.28,0-52.838,44.453-52.838,69.036c0,14.79,10.104,25.104,21.876,25.104
		c33.853,0,77.603-61.041,77.603-74.688c0-3.749-2.135-7.759-6.251-7.759c2.188,9.583-29.374,69.111-61.978,69.111
		c-28.813,0,10.506-53.408,20.485-58.921c0.586,0,0.635,0.537,0.635,0.888C1180.075,476.917,1158.278,503.321,1144.787,503.321z"
          />
        </g>
        <g>
          <path
            className="logo-hello"
            id="text-hello"
            fill="#2DD4C6"
            d="M687.276,454.562v26.44H683.5v-11.784h-15.186v11.784h-3.776v-26.44h3.776v11.369H683.5v-11.369
	H687.276z M712.698,472.202h-16.242c0.227,1.764,1.001,3.18,2.322,4.248c1.322,1.072,2.966,1.606,4.931,1.606
	c2.393,0,4.317-0.807,5.778-2.418l2.003,2.342c-0.907,1.057-2.034,1.864-3.382,2.417c-1.348,0.555-2.852,0.831-4.514,0.831
	c-2.115,0-3.991-0.434-5.628-1.304c-1.638-0.868-2.902-2.083-3.796-3.645c-0.895-1.562-1.341-3.325-1.341-5.289
	c0-1.938,0.434-3.688,1.303-5.249c0.869-1.561,2.063-2.777,3.588-3.646c1.523-0.869,3.242-1.303,5.156-1.303s3.62,0.434,5.119,1.303
	c1.498,0.868,2.669,2.085,3.512,3.646c0.843,1.562,1.266,3.35,1.266,5.363C712.773,471.384,712.748,471.75,712.698,472.202z
	 M698.513,465.441c-1.171,1.059-1.856,2.441-2.059,4.155h12.843c-0.202-1.688-0.889-3.067-2.06-4.136
	c-1.171-1.071-2.625-1.606-4.362-1.606C701.139,463.854,699.685,464.383,698.513,465.441z M717.798,452.975h3.626v28.027h-3.626
	V452.975z M728.336,452.975h3.626v28.027h-3.626V452.975z M742.085,479.907c-1.586-0.882-2.827-2.097-3.722-3.646
	c-0.894-1.55-1.341-3.306-1.341-5.271s0.447-3.721,1.341-5.269c0.895-1.548,2.136-2.759,3.722-3.627
	c1.586-0.869,3.375-1.303,5.364-1.303c1.988,0,3.771,0.434,5.345,1.303c1.574,0.868,2.808,2.079,3.702,3.627
	c0.894,1.548,1.341,3.304,1.341,5.269s-0.448,3.721-1.341,5.271c-0.895,1.549-2.127,2.764-3.702,3.646
	c-1.574,0.883-3.355,1.321-5.345,1.321C745.459,481.229,743.671,480.79,742.085,479.907z M750.904,477.17
	c1.021-0.593,1.819-1.423,2.398-2.494c0.579-1.069,0.869-2.297,0.869-3.684c0-1.385-0.29-2.61-0.869-3.683
	c-0.579-1.069-1.378-1.894-2.398-2.473c-1.021-0.58-2.172-0.869-3.456-0.869c-1.285,0-2.437,0.289-3.456,0.869
	c-1.021,0.579-1.827,1.402-2.418,2.473c-0.593,1.071-0.889,2.298-0.889,3.683c0,1.387,0.296,2.613,0.889,3.684
	c0.591,1.071,1.396,1.902,2.418,2.494c1.02,0.592,2.171,0.888,3.456,0.888C748.732,478.058,749.885,477.761,750.904,477.17z"
          />
          <path
            className="logo-hello"
            id="text-im"
            fill="#2DD4C6"
            d="M664.538,488.824h3.776v26.441h-3.776V488.824z M678.382,487.747
	c0.465,0.491,0.699,1.104,0.699,1.834c0,0.377-0.038,0.749-0.114,1.113c-0.076,0.365-0.24,0.889-0.491,1.566l-1.813,5.288h-2.379
	l1.396-5.628c-0.479-0.176-0.863-0.474-1.152-0.889c-0.289-0.416-0.434-0.899-0.434-1.452c0-0.757,0.238-1.373,0.717-1.852
	c0.479-0.479,1.083-0.718,1.813-0.718C677.33,487.012,677.915,487.258,678.382,487.747z M715.324,497.25
	c1.472,1.46,2.209,3.624,2.209,6.496v11.521h-3.626v-11.105c0-1.938-0.448-3.4-1.342-4.383c-0.894-0.98-2.172-1.472-3.834-1.472
	c-1.838,0-3.299,0.572-4.381,1.719c-1.083,1.146-1.625,2.79-1.625,4.93v10.313h-3.626v-11.105c0-1.938-0.448-3.4-1.341-4.383
	c-0.895-0.98-2.172-1.472-3.834-1.472c-1.839,0-3.299,0.572-4.382,1.719c-1.083,1.146-1.624,2.79-1.624,4.93v10.313h-3.626v-20.02
	h3.475v2.981c0.729-1.029,1.688-1.817,2.871-2.358c1.183-0.543,2.53-0.813,4.042-0.813c1.562,0,2.946,0.314,4.154,0.943
	c1.209,0.629,2.141,1.548,2.796,2.758c0.755-1.158,1.806-2.064,3.154-2.721c1.346-0.654,2.862-0.98,4.551-0.98
	C711.853,495.058,713.849,495.788,715.324,497.25z"
          />
        </g>
      </svg>
    </div>
  );
};

export default LogoComponent;
