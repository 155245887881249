/* eslint-disable max-len */
/* eslint-disable no-restricted-properties */
import React, { useState, useRef, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Plane, useCurtains } from 'react-curtains';
import { Vec2 } from 'curtainsjs';

import LoadingComponent from '../LoadingComponent';

import { homeGalleryVS, homeGalleryFS } from './imgShaders';

import './styles.scss';

const BasicPlane = ({ imgUrl }, ref) => {
  const [planeUrl] = useState(imgUrl);
  const [isLoaded, setIsLoaded] = useState(false);

  const mousePosition = useRef(new Vec2());
  const mouseLastPosition = useRef(new Vec2());

  const deltas = useRef({
    max: 0,
    applied: 0,
  });

  const planeRef = useRef();
  // console.log('forwarded ref for plane', ref, 'planeRef', planeRef);
  const tweenRef = useRef(null);

  useEffect(() => {
    const currentTween = tweenRef.current;
    return () => {
      if (currentTween) {
        currentTween.kill();
      }
    };
  }, []);

  const basicUniforms = {
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    resolution: {
      name: 'uResolution',
      type: '2f',
      value: [0, 0],
    },
    mousePosition: {
      name: 'uMousePosition',
      type: '2f',
      value: mousePosition.current,
    },
    mouseMoveStrength: {
      name: 'uMouseMoveStrength',
      type: '1f',
      value: 0,
    },
    planeDeformation: {
      name: 'uPlaneDeformation',
      type: '1f',
      value: 0,
    },
  };

  useCurtains(
    (curtains) => {
      // console.log('curtains:', curtains);
      curtains.planes.forEach((planeContainer) => {
        // console.log(planeContainer);
        const onMouseMove = (event) => {
          // Set mouse position values for last position
          mouseLastPosition.current.copy(mousePosition.current);

          const mouse = new Vec2();

          // Handles when mouse position when touching object
          if (event.targetTouches) {
            // console.log(event.targetTouches);
            mouse.set(event.targetTouches[0].clientX, event.targetTouches[0].clientY);
          } else {
            mouse.set(event.clientX, event.clientY);
          }

          // Lerps for smooooooth
          mousePosition.current.set(
            curtains.lerp(mousePosition.current.x, mouse.x, 0.3),
            curtains.lerp(mousePosition.current.y, mouse.y, 0.3)
          );

          // Calculates strength of mouvement
          if (mouseLastPosition.current.x && mouseLastPosition.current.y) {
            let delta =
              Math.sqrt(
                Math.pow(mousePosition.current.x - mouseLastPosition.current.x, 2) +
                  Math.pow(mousePosition.current.y - mouseLastPosition.current.y, 2)
              ) / 30;

            delta = Math.min(4, delta);

            // Handles delta update if increased
            if (delta >= deltas.current.max) {
              deltas.current.max = delta;
            }
          }

          // If plane, update mouse position uniforms
          if (planeContainer) {
            planeContainer.uniforms.mousePosition.value = planeContainer.mouseToPlaneCoords(mousePosition.current);
          }
        };

        // Sets listeners on planes
        if (ref?.current) {
          // Handle mouseover
          ref.current.addEventListener('mousemove', onMouseMove);
          // Handle touch
          ref.current.addEventListener('touchmove', onMouseMove, { passive: true });
        }
        // Cleans page on unmount
        return () => {
          ref.current.removeEventListener('mousemove', onMouseMove);
          ref.current.removeEventListener('touchmove', onMouseMove, { passive: true });
        };
      });
    },
    [planeUrl]
  );

  // Handles resolution
  const setResolution = (plane) => {
    const planeBBox = plane.getBoundingRect();
    plane.uniforms.resolution.value = [planeBBox.width, planeBBox.height];
  };

  // When plane ready
  const onReady = (plane) => {
    // console.log(plane.loader);
    plane.setPerspective(35);

    deltas.current.max = 2;
    setResolution(plane);
  };

  const onRender = (plane) => {
    // Time uniforms
    plane.uniforms.time.value += 1;

    // Applied deformation to deltas
    deltas.current.applied += (deltas.current.max - deltas.current.applied) * 0.02;
    deltas.current.max += (0 - deltas.current.max) * 0.01;

    // Update mouse strength value
    plane.uniforms.mouseMoveStrength.value = deltas.current.applied;

    plane.updatePosition();
  };

  const onLoading = () => {
    setIsLoaded(true);
  };

  // Resolution after resize
  const onAfterResize = (plane) => {
    setResolution(plane);
  };

  return (
    <div ref={ref} className="planeComponent">
      <div className="planeComponent-container">
        <Plane
          className="planeComponent-container_plane"
          // plane init parameters
          vertexShader={homeGalleryVS}
          fragmentShader={homeGalleryFS}
          uniforms={basicUniforms}
          widthSegments={20}
          heightSegments={20}
          // plane events
          onReady={onReady}
          onAfterResize={onAfterResize}
          onRender={onRender}
          onLoading={onLoading}
        >
          {!isLoaded && <LoadingComponent />}
          <img ref={planeRef} src={imgUrl} data-sampler="simplePlaneTexture" alt="" />
        </Plane>
      </div>
    </div>
  );
};

const forwardedRef = forwardRef(BasicPlane);

export default forwardedRef;

BasicPlane.propTypes = {
  imgUrl: PropTypes.string,
};
BasicPlane.defaultProps = {
  imgUrl: '',
};
