import { useEffect } from 'react';
import gsap from 'gsap';

import HomeGallery from '../HomeGallery';
import Contacts from '../Contacts';

import './styles.scss';

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    const titleElements = gsap.utils.toArray('.homepage-title--item');

    gsap.fromTo(
      titleElements,
      {
        opacity: 0,
        y: 200,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: 'expo',
        stagger: {
          each: 0.1,
        },
      }
    );
  }, []);

  return (
    <div className="homepage">
      <div className="homepage-title title-1">
        <span className="homepage-title--item">Creative</span>
        <span className="homepage-title--item">Web</span>
        <span className="homepage-title--item">Developper</span>
      </div>
      <HomeGallery />
      <div style={{ padding: '0 1rem' }}>
        <Contacts />
      </div>
    </div>
  );
};

export default Homepage;
