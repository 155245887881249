import { forwardRef, useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import GalleryItemHoverComponent from '../GalleryItemHoverComponent';
import { ReducerContext, ReducerDispatchContext } from '../../../reducers/appReducer';

import BasicPlane from '../../Utils/ImgPlane';

// COMPONENTS
const GalleryItem = forwardRef((props, ref) => {
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const context = useContext(ReducerContext);
  const { workIsOpen } = context;

  const dispatch = useContext(ReducerDispatchContext);

  const [isHovered, setIsHovered] = useState(false);

  const { id, title, cover, timeline, displayItem, categories, summary, slug, isCustom, selectedItem } = props;

  const { itemRef, titleRef, descRef, categoriesRef, arrowRef, headerRef, btnRef, headerCategoriesRef } = ref;

  useEffect(() => {
    if (selectedItem === id) {
      setItemIsOpen(true);
    } else {
      setItemIsOpen(false);
    }
  }, [selectedItem, id]);

  const handleDisplay = (timeline) => {
    if (!workIsOpen) {
      dispatch({
        type: 'open_work',
        id: id,
      });
    } else {
      dispatch({
        type: 'close_work',
        id: id,
      });
    }

    titleRef.current.classList.remove('bold');
    displayItem(
      timeline,
      itemRef,
      titleRef,
      descRef,
      categoriesRef,
      arrowRef,
      headerRef,
      btnRef,
      headerCategoriesRef,
      id
    );
  };

  const handleMouseEnter = () => {
    if (window.innerWidth > 1080) setIsHovered(true);
    titleRef.current.classList.add('bold');
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 1080) setIsHovered(false);
    titleRef.current.classList.remove('bold');
  };

  return (
    <>
      {isHovered && !workIsOpen && (
        <GalleryItemHoverComponent imgUrl={`/img/works/${slug}/${cover}.jpg`} workIsOpen={workIsOpen} />
      )}
      <div
        ref={itemRef}
        className="homegallery-item"
        onClick={() => {
          setIsHovered(false);
          handleDisplay(timeline);
          setItemIsOpen(!itemIsOpen);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div ref={headerRef} className="homegallery-item--headerblock">
          {/* Id */}
          <div
            className="homegallery-item--headerblock_id"
            style={{
              marginLeft: `${isHovered && !workIsOpen ? 0.5 : 0}rem`,
            }}
          >
            {id < 10 ? `0${id}` : id}
          </div>
          {/* Title */}
          <h3 ref={titleRef} className="homegallery-item--headerblock_title">
            {title}
          </h3>
          <ul ref={headerCategoriesRef} className="homegallery-item--headerblock_categories">
            |{categories && categories.map((category) => <li key={category}>{category}</li>)}
          </ul>
          {/* Arrox */}
          <p
            ref={arrowRef}
            style={{
              transform: `rotate(${isHovered ? 90 : 0}deg)`,
            }}
            className="homegallery-item--headerblock_arrow"
          >
            →
          </p>
        </div>

        <div className="homegallery-item--content">
          {/* Description */}
          <h3 ref={descRef} className="homegallery-item--content_summary">
            {summary}
          </h3>
          {/* Categories */}
          <ul ref={categoriesRef} className="homegallery-item--content_categories">
            {categories?.map((category) => (
              <li key={category}>
                <h4>{category}</h4>
              </li>
            ))}
          </ul>
          {/* Link to project */}
          <Link ref={btnRef} className="homegallery-item--content_link" to={`/work/${isCustom ? slug : id}`}>
            See more
          </Link>
        </div>
        {itemIsOpen && (
          <div
            className="homegallery-item--image"
            style={{
              position: 'absolute',
              overflow: 'hidden',
              top: 0,
              right: 0,
              bottom: 0,
              left: -50,
              // display: `${workIsOpen ? 'block' : 'none'}`,
            }}
          >
            <BasicPlane imgUrl={`/img/works/${slug}/${cover}.jpg`} ref={itemRef} />
          </div>
        )}
      </div>
    </>
  );
});

export default GalleryItem;
