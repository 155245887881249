import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import LogoComponent from '../LogoComponent';

import './styles.scss';

const Navigation = () => {
  const location = useLocation();
  return (
    <nav className="navigation">
      <NavLink className="navigation-logo black" to="/">
        <img className="navigation-logo--img" src="/img/id/logo-antoine.svg" alt="logo antoine garcia" />
      </NavLink>
      <NavLink className="black" style={location.pathname === '/' ? { textAlign: 'end' } : {}} to="/about">
        About
      </NavLink>
    </nav>
  );
};

export default Navigation;
