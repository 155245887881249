import { useRef, createRef, useEffect, useState, useContext } from 'react';
import gsap from 'gsap';

import GalleryItem from './GalleryItem';

import './styles.scss';
import { ReducerContext } from '../../reducers/appReducer';

const HomeGallery = () => {
  const context = useContext(ReducerContext);
  const { works } = context;

  // Previous elements
  const [prevEl, setPrevEl] = useState(null);
  const [prevTitle, setPrevTitle] = useState(null);
  const [prevArrow, setPrevArrow] = useState(null);
  const [prevHeader, setPrevHeader] = useState(null);
  const [prevHeaderCategories, setPrevHeaderCategories] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  // Timelines
  const displayworksTl = useRef();
  displayworksTl.current = gsap.timeline({ paused: true });

  // Display work items animation
  useEffect(() => {
    const items = gsap.utils.toArray('.homegallery-item');
    gsap.fromTo(
      items,
      {
        y: 150,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        stagger: {
          each: 0.05,
        },
      }
    );
  }, []);

  // Open work item animation
  const displayItem = (
    timeline,
    itemRef,
    titleRef,
    descRef,
    categoriesRef,
    arrowRef,
    headerRef,
    btnRef,
    headerCategoriesRef,
    id
  ) => {
    const element = itemRef.current;
    const title = titleRef.current;
    const header = headerRef.current;
    const description = descRef.current;
    const categories = categoriesRef.current;
    const headerCategories = headerCategoriesRef.current;
    const arrow = arrowRef.current;
    const btn = btnRef.current;

    setSelectedItem(id);

    // If previous element => resize previous element
    if (!!prevEl && prevEl !== element) {
      timeline
        .to(prevEl, {
          height: `${window.innerWidth > 600 ? '5rem' : '4rem'}`,
          duration: 0.3,
          ease: 'expo',
        })
        .to(
          prevHeader,
          {
            backgroundColor: '#030d13',
            ease: 'expo',
            duration: 0.5,
          },
          '<'
        )
        .to(
          prevTitle,
          {
            fontSize: (i, el) => gsap.getProperty(el, '--small'),
            backgroundColor: '#030d13',
            ease: 'expo',
            duration: 0.5,
          },
          '<'
        )
        .to(
          prevArrow,
          {
            rotate: 0,
            ease: 'expo',
            duration: 0.5,
          },
          '<'
        )
        .to(
          prevHeaderCategories,
          {
            opacity: 1,
          },
          '<'
        );
    }

    // If same element resizes to initial and set prevElement to null
    if (prevEl === element) {
      timeline
        .to(prevEl, {
          height: `${window.innerWidth > 600 ? '5rem' : '4rem'}`,
          duration: 0.3,
          ease: 'expo',
        })
        .to(
          header,
          {
            backgroundColor: '#030d13',
            ease: 'expo',
            duration: 0.5,
          },
          '<'
        )
        .to(
          title,
          {
            fontSize: (i, el) => gsap.getProperty(el, '--small'),
            backgroundColor: '#030d13',
            ease: 'expo',
            duration: 0.5,
            onComplete: () => {
              setPrevEl(null);
              setPrevTitle(null);
              setPrevHeader(null);
              setPrevHeaderCategories(null);
              setPrevArrow(null);
            },
          },
          '<'
        )
        .to(
          arrow,
          {
            rotate: 0,
            ease: 'expo',
            duration: 0.5,
          },
          '<'
        )
        .to(
          headerCategories,
          {
            opacity: 1,
          },
          '<'
        );
      // else plays timeline and set previous element when complete
    } else {
      timeline
        .to(
          element,
          {
            height: '35rem',
            duration: 1,
            ease: 'expo',
            onComplete: () => {
              if (prevEl !== element) {
                setPrevEl(element);
                setPrevTitle(title);
                setPrevArrow(arrow);
                setPrevHeader(header);
                setPrevHeaderCategories(headerCategories);
              }
            },
          },
          '<'
        )
        .to(
          headerCategories,
          {
            opacity: 0,
          },
          '<'
        )
        .to(
          header,
          {
            backgroundColor: '#030d1300',
            ease: 'expo',
            duration: 0.5,
          },
          '<'
        )
        .to(
          title,
          {
            fontSize: (i, el) => gsap.getProperty(el, '--title'),
            backgroundColor: '#030d1300',
            ease: 'expo',
            duration: 0.5,
          },
          '<'
        )
        .to(
          arrow,
          {
            rotate: '90deg',
            ease: 'expo',
            duration: 0.5,
          },
          '<'
        )
        .fromTo(
          description,
          {
            y: 100,
          },
          {
            y: -20,
            ease: 'expo',
            duration: 1,
          },
          '<'
        )
        .fromTo(
          categories,
          {
            y: 100,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            ease: 'expo',
            duration: 1,
          },
          '>-80%'
        )
        .fromTo(
          btn,
          {
            y: 100,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            ease: 'expo',
            duration: 1,
          },
          '>-80%'
        );
    }

    timeline.play();
  };

  useEffect(() => {
    return () => {
      displayworksTl.current.kill();
    };
  }, []);

  return (
    <div className="homegallery">
      {works &&
        works?.map((work) => {
          // Refs for animation
          const itemRef = createRef();
          const titleRef = createRef();
          const descRef = createRef();
          const categoriesRef = createRef();
          const arrowRef = createRef();
          const headerRef = createRef();
          const btnRef = createRef();
          const headerCategoriesRef = createRef();

          // Props
          const { title, coverImg, id, categories, summary, slug, isCustom } = work;

          return (
            <GalleryItem
              timeline={displayworksTl?.current}
              displayItem={displayItem}
              title={title}
              cover={coverImg}
              id={id}
              slug={slug}
              ref={{
                itemRef,
                titleRef,
                descRef,
                categoriesRef,
                arrowRef,
                headerRef,
                btnRef,
                headerCategoriesRef,
              }}
              key={id}
              categories={categories}
              summary={summary}
              isCustom={isCustom}
              selectedItem={selectedItem}
            />
          );
        })}
    </div>
  );
};

export default HomeGallery;
