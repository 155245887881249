import { useState, useEffect, useReducer } from 'react';

import { Routes, Route, useLocation } from 'react-router-dom';

// Context
import { initialState, ReducerContext, ReducerDispatchContext } from '../../reducers/appReducer';
import appReducer from '../../reducers/appReducer';

import Homepage from '../Homepage';
import Navigation from '../Navigation';
import About from '../About';
import FourOFourComponent from '../404Component';

import InitialLoading from '../Utils/InitialLoading';
import LoadingComponent from '../Utils/LoadingComponent';

// Custom Works components
import FormacoachComponent from '../CustomWorkPages/FormacoachComponent';
import DivinStudioComponent from '../CustomWorkPages/DivinStudioComponent';

import WorkDetails from '../WorkDetailsPage';

import './App.scss';

const TransitionPageComponent = ({ children }) => {
  return children;
};

function App() {
  const [initialLoading, setInitialLoading] = useState(true);
  const [reducer, dispatch] = useReducer(appReducer, initialState);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <ReducerContext.Provider value={reducer}>
      <ReducerDispatchContext.Provider value={dispatch}>
        <div className="App">
          {initialLoading ? (
            <InitialLoading setInitialLoading={setInitialLoading} />
          ) : (
            <>
              <Navigation />
              <Routes>
                <Route
                  path="/"
                  element={
                    <TransitionPageComponent>
                      <Homepage />
                    </TransitionPageComponent>
                  }
                />
                <Route
                  path="/about"
                  element={
                    <TransitionPageComponent>
                      <About />
                    </TransitionPageComponent>
                  }
                />
                <Route
                  path="/work/:id"
                  element={
                    <TransitionPageComponent>
                      <WorkDetails />
                    </TransitionPageComponent>
                  }
                />
                <Route
                  path="/work/formacoach"
                  element={
                    <TransitionPageComponent>
                      <FormacoachComponent />
                    </TransitionPageComponent>
                  }
                />
                <Route
                  path="/work/divinstudio"
                  element={
                    <TransitionPageComponent>
                      <DivinStudioComponent />
                    </TransitionPageComponent>
                  }
                />
                <Route
                  path="*"
                  element={
                    <TransitionPageComponent>
                      <FourOFourComponent />
                    </TransitionPageComponent>
                  }
                />
              </Routes>
            </>
          )}
        </div>
      </ReducerDispatchContext.Provider>
    </ReducerContext.Provider>
  );
}

export default App;
