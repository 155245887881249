import { useEffect } from 'react';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';

import './styles.scss';

const Contacts = () => {
  gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

  const date = new Date();
  const year = date?.getFullYear();

  useEffect(() => {
    gsap.fromTo(
      '.contacts',
      {
        y: 150,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        ease: 'expo',
      }
    );
  }, []);

  // const handleBackToTop = () => {
  //   gsap.to(window, {
  //     scrollTo: { y: 0, x: 0 },
  //     duration: 0.5,
  //     ease: 'expo',
  //   });
  // };

  return (
    <div className="contacts">
      <h1 className="title-1">Contacts</h1>
      <div className="contacts-grid">
        <div className="contacts-grid--left">
          <a href="mailto:hello@antoinegarcia.com">hello@antoinegarcia.com</a>
          <a href="tel:+33645841273">+33(6) 45 84 12 73</a>
          <h3>Paris, France</h3>
        </div>
        <div className="contacts-grid--center">
          <a href="https://www.linkedin.com/in/garciaantoine/" target={'_blank'} rel="external noreferrer">
            <h3>Linkedin</h3>
          </a>
          <a href="https://www.instagram.com/garci_ntoine/" target={'_blank'} rel="external noreferrer">
            <h3>Instagram</h3>
          </a>
          <a href="https://github.com/Antoine75-JS" target={'_blank'} rel="external noreferrer">
            <h3>Github</h3>
          </a>
        </div>
        <div className="contacts-grid--right">
          <div className="copyright">
            Designed & developped with love (and React) by Antoine Garcia. Copyright {year || 2022}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
