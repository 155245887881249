import { useEffect, useRef } from 'react';
import gsap from 'gsap';

import './styles.scss';
import LogoComponent from '../../LogoComponent';

const InitialLoading = ({ setInitialLoading }) => {
  const loadingTl = useRef();

  loadingTl.current = gsap.timeline({
    paused: true,
    defaults: {
      ease: 'expo',
    },
  });

  const animateLoading = () => {
    loadingTl.current
      .to('.initial-loading--panel_1', { top: 0, duration: 0.7 })
      .to('.initial-loading--panel_2', { top: 0, duration: 0.5 }, '-=50%')
      .to('.initial-loading--logo', { opacity: 0, duration: 0.5, delay: 4 })
      .to('.initial-loading--panel_2', { top: '-200%', duration: 0.7 })
      .to('.initial-loading--panel_1', { top: '-200%', duration: 0.9 }, '-=50%')
      .to('.initial-loading', { display: 'none', onComplete: () => setInitialLoading(false) });

    loadingTl.current.play();
  };

  useEffect(() => {
    animateLoading();

    return () => {
      loadingTl.current.kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="initial-loading">
      <div className="initial-loading--logo">
        <LogoComponent />
      </div>
      <span className="initial-loading--panel_1" />
      <span className="initial-loading--panel_2" />
    </div>
  );
};

export default InitialLoading;
