import { useState, useEffect } from 'react';
import works from '../../../data/works.json';
import gsap from 'gsap';

import Contacts from '../../Contacts';

import './styles.scss';
import ImgParallaxComponent from '../../Utils/ImgParallaxComponent';
import WorkDetailsHeader from '../../WorkDetailsPage/WorkDetailsHeader';
import VideoComponent from '../../Utils/VideoComponent';

const FormacoachComponent = () => {
  const [workDetails, setWorkDetails] = useState(null);

  useEffect(() => {
    setWorkDetails(...works?.filter((work) => work.slug === 'formacoach'));
  }, []);

  useEffect(() => {
    const images = gsap.utils.toArray('.gsap-parallax-image');

    images.forEach((image, i) => {
      gsap.fromTo(
        image,
        {
          y: (image.parentElement.offsetHeight - image.offsetHeight) * 2,
        },
        {
          y: 0,
          ease: 'none',
          scrollTrigger: {
            trigger: image.parentElement,
            scrub: true,
            // markers: true,
          },
        }
      );
    });
  }, []);

  return (
    <div className="formacoach">
      <WorkDetailsHeader
        title={workDetails?.title}
        backgroundUrl={'url(/img/works/formacoach/formacoach-mockup.png)'}
      />
      {/* DETAILS 2 */}
      <p className="formacoach-summary">
        Création de l'identité visuelle et développement du site Internet de Forma'Coach digital, entreprise de coaching
        et de formation.
      </p>
      {/* VIDEO */}
      <VideoComponent
        posterUrl={'/img/works/formacoach/formacoach-1.jpg'}
        videoUrl={'/img/works/formacoach/formacoach-scroll.mp4'}
      />
      {/* CONTENT DETAILS */}
      <div className="formacoach-content">
        {/* DESKTOP MOCKUPS */}
        <div className="formacoach-content--desktops">
          <img
            className="formacoach-content--desktops_image"
            src="/img/works/formacoach/formacoach-client-mockup-about.png"
            alt="formacoach about mockup"
          />
          <img
            className="formacoach-content--desktops_image"
            src="/img/works/formacoach/formacoach-client-mockup-homepage.png"
            alt="formacoach homepage mockup"
          />
        </div>
        {/* BACKOFFICE MOCKUPS */}
        <p className="formacoach-summary">
          Création d'un backoffice sur mesure pour la gestion des formations & ateliers
        </p>
        <div className="formacoach-content--mobile">
          <img
            className="formacoach-content--mobile_image"
            src="/img/works/formacoach/formacoach-hp-mockup.png"
            alt="formacoach homepage mockup"
          />
          <img
            className="formacoach-content--mobile_image"
            src="/img/works/formacoach/formacoach-formations-mockup.png"
            alt="formacoach formations mockup"
          />
          <img
            className="formacoach-content--mobile_image"
            src="/img/works/formacoach/formacoach-form-mockup.png"
            alt="formacoach form mockup"
          />
          <img
            className="formacoach-content--mobile_image"
            src="/img/works/formacoach/formacoach-details-mockup.png"
            alt="formacoach details mockup"
          />
        </div>
        {/* TECHNOS + LOGOS */}
        <span style={{ display: 'flex', gap: '0.5rem' }}>
          Technologies utilisées :<p className="bold ">React / Nodejs / Mongodb / Docker / Nginx /</p>
        </span>
        <div className="formacoach-content--logo">
          <img
            className="formacoach-content--logo_item"
            src="/img/works/formacoach/formacoach-logo.png"
            alt="formacoach logo"
          />
        </div>
        <span style={{ display: 'flex', gap: '0.5rem' }}>Propositions de logo non retenues :</span>
        <div className="formacoach-content--signature">
          <img
            className="formacoach-content--signature_image"
            src="/img/works/formacoach/formacoach-signature-logo-white.png"
            alt="formacoach signature logo"
          />
          <img
            className="formacoach-content--signature_image"
            src="/img/works/formacoach/formacoach-signature-logo-gradient.png"
            alt="formacoach signature logo"
          />
        </div>
      </div>
      {/* LINK */}
      <div className="formacoach-link">
        Visiter le site :{' '}
        <a className="bold" href="https://formacoachdigital.com" target={'_blank'} rel="noreferrer">
          formacoachdigital.com
        </a>
      </div>

      <Contacts />
    </div>
  );
};

export default FormacoachComponent;
