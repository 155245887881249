import { useState, useEffect } from 'react';

import works from '../../../data/works.json';

import './styles.scss';
import LogoAnimationComponent from './LogoAnimationComponent';
import IllustrationsAnimationComponents from './IllustrationsAnimationComponents';
import Contacts from '../../Contacts';
import VideoComponent from '../../Utils/VideoComponent';

const DivInStudioComponent = () => {
  const [workDetails, setWorkDetails] = useState(null);

  useEffect(() => {
    setWorkDetails(...works?.filter((work) => work.slug === 'divinstudio'));
  }, []);

  return (
    <div className="divin">
      <h1 className="divin-title title-1">{workDetails?.title}</h1>
      <LogoAnimationComponent />

      <p className="divin-summary">{workDetails?.description}</p>
      <IllustrationsAnimationComponents />
      <div className="divin-lang ">
        <h3 className="bold">Technologies utilisées :</h3>
        {workDetails?.dev_languages?.map((lang) => (
          <p key={lang}>{lang}</p>
        ))}
      </div>
      <VideoComponent
        posterUrl={'/img/works/divinstudio/divinstudio-1.jpg'}
        videoUrl={'/img/works/divinstudio/divinstudio-scroll.mp4'}
      />
      <Contacts />
    </div>
  );
};

export default DivInStudioComponent;
