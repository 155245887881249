import { useEffect, useMemo, useRef } from 'react';
import gsap from 'gsap';

const GalleryItemHoverComponent = ({ imgUrl, workIsOpen }) => {
  const imgCursor = useRef(null);
  const positionRef = useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  });

  const memoedUrl = useMemo(() => {
    return imgUrl;
  }, [imgUrl]);

  useEffect(() => {
    gsap.fromTo(
      imgCursor.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0.1,
        duration: 0.3,
      }
    );
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1024) {
      document.addEventListener('mousemove', (event) => {
        const { clientX, clientY } = event;
        const mouseX = clientX;
        const mouseY = clientY;

        if (positionRef.current) {
          positionRef.current.mouseX = mouseX - imgCursor?.current?.clientWidth / 2;
          positionRef.current.mouseY = mouseY - imgCursor?.current?.clientHeight / 2;
        }
      });
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1024) {
      const followMouse = () => {
        positionRef.current.key = requestAnimationFrame(followMouse);
        const { mouseX, mouseY, destinationX, destinationY, distanceX, distanceY } = positionRef.current;
        if (!destinationX || !destinationY) {
          positionRef.current.destinationX = mouseX;
          positionRef.current.destinationY = mouseY;
        } else {
          positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
          positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
          if (Math.abs(positionRef.current.distanceX) + Math.abs(positionRef.current.distanceY) < 0.1) {
            positionRef.current.destinationX = mouseX;
            positionRef.current.destinationY = mouseY;
          } else {
            positionRef.current.destinationX += distanceX;
            positionRef.current.destinationY += distanceY;
          }
        }
        if (imgCursor.current)
          imgCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
      };
      followMouse();
    }
  }, []);

  return (
    <>
      {!workIsOpen && (
        <div
          className="homegallery-hover"
          ref={imgCursor}
          style={{
            opacity: `${!workIsOpen ? 0 : 1}`,
            backgroundImage: `url(${memoedUrl || '/img/works/divinstudio/divinstudio-1.jpg'})`,
            zIndex: 99999,
          }}
        />
      )}
    </>
  );
};

export default GalleryItemHoverComponent;
